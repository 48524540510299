import styled from "@emotion/styled";
import { fontRoboto, theme } from "@product/scmp-sdk";

import {
  StyledImage as ArticleImage,
  DescriptionContainer as ArticleImageDescriptionContainer,
  DescriptionContainer as ArticleImageTitle,
} from "scmp-app/components/article/article-image/styles";

export const FavouriteContentContainer = styled.div`
  display: flex;
  flex: 1 1 0;
  flex-direction: column;

  max-inline-size: 265px;
  margin-block: 0 40px;
  margin-inline: auto;

  ${theme.breakpoints.up("tablet")} {
    margin-block-end: 50px;
    margin-inline-end: 48px;
  }

  ${ArticleImageDescriptionContainer} {
    text-align: center;
    ${ArticleImageTitle} {
      color: #000000;
      font-size: 20px;
      font-family: ${fontRoboto};
      line-height: 24px;
    }
  }

  ${ArticleImage} {
    inline-size: 265px !important;
    block-size: 265px !important;
    margin: auto;
    margin-block-end: 20px;

    border-radius: 265px;

    background-color: #7f7f7f;
  }
`;
