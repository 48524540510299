import type { NextPageContext, NextPageWithLayout } from "next";
import Head from "next/head";
import type { PreloadedQuery } from "react-relay";
import { usePreloadedQuery } from "react-relay";

import { ViewportTag } from "shared/components/meta";

import { Header } from "scmp-app/components/not-found/header";
import { Main } from "scmp-app/components/not-found/main";
import { getRelayNextStaticProps } from "scmp-app/lib/relay-nextjs";
import type { notFoundPageQuery } from "scmp-app/queries/__generated__/notFoundPageQuery.graphql";

import { notFoundQuery } from "./not-found.query";
import { PageContainer } from "./styles";

type Props = {
  preloadedQuery: PreloadedQuery<notFoundPageQuery, Record<string, unknown>>;
};

const NotFound: NextPageWithLayout<Props> = ({ preloadedQuery }) => {
  const data = usePreloadedQuery(notFoundQuery, preloadedQuery);
  return (
    <>
      <Header />
      {data?.queue && <Main reference={data.queue} />}
    </>
  );
};

export function getStaticProps(context: NextPageContext) {
  return getRelayNextStaticProps(context, notFoundQuery, {});
}

NotFound.getLayout = function getLayout(page) {
  return (
    <PageContainer>
      <Head>
        <title>Page Not Found :(</title>
        <meta content={ViewportTag.content} name={ViewportTag.name} />
      </Head>
      {page}
    </PageContainer>
  );
};

NotFound.displayName = "NotFound";

export default NotFound;
